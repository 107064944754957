<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="级别" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            style="width: 140px"
            placeholder="选择"
            v-decorator="['level', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
          >
            <a-select-option :value="1">
              一级类目
            </a-select-option>
            <a-select-option :value="2">
              二级类目
            </a-select-option>
            <a-select-option :value="3">
              三级类目
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            v-decorator="['code', {rules: [{ required: true, message: '请输入' }]}]" />
        </a-form-item>
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', {rules: [{required: true, message: '请输入'}]}]" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>

export default {
  name: 'GoodsCategory',
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this)
    }
  }
}
</script>
