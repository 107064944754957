<template>
  <div>
    <a-spin :spinning="spinning">
      <a-form :form="form">
        <a-form-item label="级别" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            disabled
            style="width: 140px"
            placeholder="选择"
            v-decorator="['level', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
          >
            <a-select-option :value="1">
              一级类目
            </a-select-option>
            <a-select-option :value="2">
              二级类目
            </a-select-option>
            <a-select-option :value="3">
              三级类目
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            v-decorator="['code', {rules: [{ required: true, message: '请输入' }]}]" />
        </a-form-item>
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', {rules: [{required: true, message: '请输入'}]}]" />
        </a-form-item>

        <a-form-item
          v-bind="buttonCol"
        >
          <a-row>
            <a-col span="6">
              <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
            </a-col>
            <a-col span="10">
              <a-button @click="handleGoBack">返回</a-button>
            </a-col>
            <a-col span="8"></a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { goods_category_update } from '@/api/c_wms_goods'

const fields = ['name', 'level', 'code']
// const fields_ = ['category_name', 'parent_id', 'category_level', 'sort_order', 'icon_url', 'picture_url']
export default {
  name: 'GoodsEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      spinning: true,
      id: 0
    }
  },
  mounted () {
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      this.id = data.id
      const formData = pick(data, fields)
      console.log('formData', formData, fields)
      form.setFieldsValue(formData)
      this.spinning = false
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          if (!this.checkValuesIsOk(values)) {
            this.$message.warning('层级和编号长度不对应')
            this.confirmLoading = false
            return
          }
          goods_category_update(values, this.id)
            .then((res) => {
              this.handleGoBack()
              console.log(res, '修改成功')
            })
        }
      })
    },
    handleGoBack () {
      this.$emit('onGoBack')
    },
    checkValuesIsOk (values) {
      return values.code.length === values.level * 2
    }
  }
}
</script>
